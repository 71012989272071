import { useContext, useEffect, useRef, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import icon7 from "../../../../../../../assets/icon7.png";

export default function AddImage() {
  const { setDesignPanelMenu, image, setImage, sizeImage, setSizeImage } =
    useContext(HomeContext);

  const handleSizeImage = (button) => {
    if (button === "plus" && sizeImage < 2) {
      setSizeImage((prevSize) => parseFloat((prevSize + 0.1).toFixed(1)));
    } else if (button === "mines" && sizeImage > 0) {
      setSizeImage((prevSize) => parseFloat((prevSize - 0.1).toFixed(1)));
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="AddImage">
        <div className="AddImage-container">
          <div className="AddImage-title">
            <span>Add Image</span>
          </div>
          <div className="AddImage-content">
            <div className="Image">
              {image && image !== "/textures/logo-silver.png" ? (
                <div className="Image-view">
                  <img src={image} alt="image" />
                  <div
                    className="Image-view-close"
                    onClick={() => {
                      setImage(null);
                    }}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                </div>
              ) : (
                <div
                  className="Image-drag"
                  onClick={() => {
                    setDesignPanelMenu(21);
                  }}
                >
                  <img src={icon7} alt="image1" />
                  <button className="Image-drag-browseBtn">Brows Files</button>
                </div>
              )}
            </div>
          </div>

          {image && (
            <div className="ImageSize">
              <button
                className="ImageSize-icon"
                onClick={() => {
                  handleSizeImage("mines");
                }}
              >
                <i className="bi bi-dash-lg"></i>
              </button>
              <div className="ImageSize-text">Size</div>
              <button
                className="ImageSize-icon"
                onClick={() => {
                  handleSizeImage("plus");
                }}
              >
                <i className="bi bi-plus-lg"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
