import { useContext, useRef } from "react";
import rule1 from "../../../../../../../../assets/rule1.png";
import rule2 from "../../../../../../../../assets/rule2.png";
import { HomeContext } from "../../../../../../Context/HomeContext";

export default function ImageRules() {
  const { setDesignPanelMenu, setImage } = useContext(HomeContext);

  const inputRef = useRef(null);

  const handleImageChange = (e) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setDesignPanelMenu(22);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
    
  };

  const handleRemove = () => {
    setImage(null);
  };

  return (
    <>
      <div className="ImageRules">
        <div className="ImageRules-title">
          <span>Picture Rules</span>
        </div>

        <div className="ImageRules-container">
          <div>
            <section className="ImageRules-row1">
              <div className="ImageRules-row1-item">
                <img src={rule1} alt="rule1" />
                <div>
                  <p>1-2 faces, simple background</p>
                </div>
              </div>
              <div className="ImageRules-row1-item">
                <img src={rule1} alt="rule1" />
                <div>
                  <p>Good lighting</p>
                </div>
              </div>
              <div className="ImageRules-row1-item">
                <img src={rule2} alt="rule2" />
                <div>
                  <p>No full body photos or 3+ people</p>
                </div>
              </div>
            </section>

            <section className="ImageRules-row2">
              <h6 className="ImageRules-row2-title">
                Image Requirements (Photos will be rejected if guidelines are
                not followed)
              </h6>
              <ul className="ImageRules-row2-text">
                <li>
                  Only 1-2 faces cheek to cheek. Close-ups and images with
                  minimal backgrounds will produce the best results.
                </li>
                <li>
                  Do not upload photos with detailed backgrounds. Backgrounds
                  are removed. No landscape pictures.
                </li>
                <li>
                  You must upload a .jpg file between 5 & 15MB. The higher the
                  quality, the better. The system will not allow images larger
                  than 15MB.
                </li>
                <li>
                  HINT: Images and designs print better on light colored M&M’S.
                </li>
              </ul>
              <h6 className="ImageRules-row2-title">For logos:</h6>
              <ul className="ImageRules-row2-text">
                <li>Submitted photos must be your own private property.</li>
                <li>
                  M&M'S® will not print any copyrighted or trademarked images or
                  logos, unless the proper legal permission has been obtained
                  from the owner.
                </li>
                <li>
                  For more information about logo printing, please contact our 
                  <u>Business Consultants</u> directly.
                </li>
                <li>
                  <b>
                    Image Requirements (Photos will be rejected if guidelines
                    are not followed)
                  </b>
                </li>
                <li>
                  Only 1-2 faces cheek to cheek. Close-ups and images with
                  minimal backgrounds will produce the best results.
                </li>
                <li>
                  Do not upload photos with detailed backgrounds. Backgrounds
                  are removed. No landscape pictures.
                </li>
                <li>
                  You must upload a .jpg file between 5 & 15MB. The higher the
                  quality, the better. The system will not allow images larger
                  than 15MB.
                </li>
                <li>
                  HINT: Images and designs print better on light colored M&M’S.
                </li>
                <li>
                  <b>For logos:</b>
                </li>
                <li>Submitted photos must be your own private property.</li>
                <li>
                  M&M'S® will not print any copyrighted or trademarked images or
                  logos, unless the proper legal permission has been obtained
                  from the owner.
                </li>
                <li>
                  For more information about logo printing, please contact our 
                  <u>Business Consultants</u>  directly.
                </li>
              </ul>
            </section>
          </div>
        </div>

        <button className="ImageRules-confirmBtn" onClick={handleClick}>
          <span>Confirm & Upload</span>
          <input
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={handleImageChange}
          />
        </button>
      </div>
    </>
  );
}
