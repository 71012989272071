import Quabtity from "./Components/Quantity/Quantity";
import Material from "./Components/Material/Material";
import Boxes from "./Components/Boxes/Boxes";

export default function Packaging() {
  return (
    <>
      <div className="DesignPanel-tools-packaging">
        <div className="DesignPanel-tools-packaging-container">
          <Material />
          <Quabtity />
          <Boxes />
        </div>
      </div>
    </>
  );
}
