import HomePage from "./Pages/Home/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/MI.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from "react";


function App() {

  useEffect(() => {
    document.title = "speakingRose";
  }, [])

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<HomePage />} />
        </Routes>

      </Router>

    </>
  );
}

export default App;
