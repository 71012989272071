import { useContext, useEffect } from "react";
import { HomeContext } from "../../../Context/HomeContext";

export default function Price() {
  const { cost, setAddToCard } = useContext(HomeContext);


  return (
    <>
      <div className="Price">
        <div className="Price-container">
          <section className="Price-number">
            <p>Bill</p>
            <span>{cost} $</span>
          </section>
          <section className="Price-add">
            <button
              onClick={() => {
                setAddToCard(true);
              }}
            >
              Add to card
            </button>
          </section>
        </div>
      </div>
    </>
  );
}
