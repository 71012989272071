import chat from "../../../../assets/chat.png";

export default function Chat() {
  return (
    <>
      <div className="Chat">
        <img src={chat} alt="chat" />
      </div>
    </>
  );
}
