import { createContext, useState } from "react";
import dataClient from "../../../assets/Json/dataClient.json";

export const HomeContext = createContext();

export default function HomeContextProvider(props) {
  // Design Tabs
  const [designPanelMenu, setDesignPanelMenu] = useState(1);
  const [activeTab, setActiveTab] = useState("AddText");

  // Rose
  const [rose, setRose] = useState({
    roseType: "Short-Stem-Rose",
    roseColor: dataClient?.design?.colorRose[0],
  });

  // Text Design
  const [textDesign, setTextDesign] = useState({
    fontFamily: "Roboto",
    fontFamilyPath: "/CustomizerFont/Roboto-VariableFont_wdth,wght.ttf",
    selectedTextColor: {
      id: 1,
      hex: "#000000",
      check: "#FFFFFF",
    },
    text: {
      line1: "Your",
      line2: "Design",
      line3: "Here",
      line4: "",
    },
  });

  // Image Tab
  const [image, setImage] = useState("/textures/logo-silver.png");

  // Clipar Tab
  const [cliPart, setCliPart] = useState();

  // Packaging Part
  const [material, setMaterial] = useState({
    id: 1,
    title: "acrylic",
  });
  const [quantity, setQuantity] = useState(
    dataClient.design?.packaging?.quantity[0]
  );
  const [boxSelected, setBoxSelected] = useState();

  // Order Part
  const [isActive1, setIsActive1] = useState(true);
  const [activeSection, setActiveSection] = useState(1);
  const [prevActiveSection, setPrevActiveSection] = useState();

  const sectionRefs = Array(3)
    .fill(null)
    .map(() => ({ current: null })); // ایجاد رفرنس برای هر بخش

  const handleScrollTo = (index) => {
    const section = sectionRefs[index].current;
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
      // setActiveSection(index + 1);
    }
  };


  // Size Image
  const [sizeImage, setSizeImage] = useState(1);

  // Price Part
  const [cost, setCost] = useState(200);
  const [addToCard, setAddToCard] = useState(false);

  // Loading
  const [loading, setLoading] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);

  const [flagScroll, setFlagScroll] = useState(false);

  const contextValue = {
    rose,
    setRose,
    textDesign,
    setTextDesign,
    material,
    setMaterial,
    boxSelected,
    setBoxSelected,
    loading,
    setLoading,
    loadingMain,
    setLoadingMain,
    designPanelMenu,
    setDesignPanelMenu,
    image,
    setImage,
    quantity,
    setQuantity,
    cliPart,
    setCliPart,
    activeSection,
    setActiveSection,
    handleScrollTo,
    sectionRefs, // ارسال کل آبجکت رفرنس (همان useRef)
    activeTab,
    setActiveTab,
    cost,
    setCost,
    addToCard,
    setAddToCard,
    isActive1,
    setIsActive1,
    prevActiveSection,
    setPrevActiveSection,
    sizeImage,
    setSizeImage,
    flagScroll,
    setFlagScroll,
  };
  return (
    <HomeContext.Provider value={contextValue}>
      {props.children}
    </HomeContext.Provider>
  );
}
