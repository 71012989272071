import { useEffect } from "react";
import CanvasPart from "./Components/CanvasPart/CanvasPart";
import DesignPanel from "./Components/DesignPanel/DesignPanel";
import DesignPanelToggler from "./Components/DesignPanelToggler/DesignPanelToggler";
import HomeContextProvider from "./Context/HomeContext";
import LoadingMain from "./Components/LoadingMain/LoadingMain";
import dataClient from "../../assets/Json/dataClient.json";
import Chat from "./Components/Chat/Chat";
import { useState } from "react";

export default function HomePage() {
  const [generalHeight, setGeneralHeight] = useState("100vh");

  const updateHeight = () => {
    if (window.innerWidth < 992) {
      setGeneralHeight(window.innerHeight);
      
    } else {
      setGeneralHeight("100vh");
    }

    
  };

  useEffect(() => {
    const fonts = dataClient?.design?.text?.textFont;

    const generateFontFaces = (fontArray) => {
      return fontArray
        .map(
          (font) => `
          @font-face {
            font-family: '${font.name}';
            src: url(${font.url});
          }
        `
        )
        .join("");
    };

    const applyDynamicFonts = (fontArray) => {
      const style = document.createElement("style");
      style.innerHTML = generateFontFaces(fontArray);
      document.head.appendChild(style);
    };

    applyDynamicFonts(fonts);
  }, []);

  useEffect(() => {
    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect (() => {
    console.log(generalHeight);
  } , [generalHeight])

  return (
    <>
      <main className="Home" style={{ height: generalHeight }}>
        <div className="Home-container ">
          <HomeContextProvider>
            <section className="ShowPart ">
              <CanvasPart />
            </section>

            <section className="DesignPanel row">
              <DesignPanel />
            </section>
            <div className="Chat-max992">
              <Chat />
            </div>
            <LoadingMain />
          </HomeContextProvider>
        </div>
      </main>
    </>
  );
}
