import { useContext, useEffect, useRef, useState } from "react";
import logo from "../../../../assets/logo.png";
import * as THREE from "three";
import { RoomEnvironment } from "three/addons/environments/RoomEnvironment.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader"; // Import RGBELoader
import dataClient from "../../../../assets/Json/dataClient.json";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { HomeContext } from "../../Context/HomeContext";
import { normalize } from "three/src/math/MathUtils";
import Chat from "../Chat/Chat";

export default function CanvasPart() {
  const canvasRef = useRef();
  const modelRef = useRef([]);
  const sceneRef = useRef();
  const cameraRef = useRef();
  const orbitRef = useRef();
  const rendererRef = useRef();
  const firstLoad = useRef(true);
  const [loadModel, setLoadModel] = useState(false);
  const [activeDesign, setActiveDesign] = useState(null);

  let newBaseColorTexture;
  let newNormalMapTexture, newNormalMapBaseRose;
  let newHeightMapTexture;
  let newRoughnessMapTexture;

  const zoomStep = 0.2;
  const minZoomDistance = 0.1;
  const maxZoomDistance = 30;

  const {
    rose,
    textDesign,
    loadingMain,
    setLoadingMain,
    material,
    quantity,
    boxSelected,
    image,
    cliPart,
    activeTab,
    activeSection, // 1: Rose  2: Design  3: Packaging
    prevActiveSection,
    setCost,
    setAddToCard,
    addToCard,
    sizeImage,
    flagScroll,
    setFlagScroll,
  } = useContext(HomeContext);

  useEffect(() => {
    console.log("canvas-textDesign>>", textDesign);
    console.log("canvas-cliPart>>", cliPart);
  }, []);

  useEffect(() => {
    setActiveDesign(activeTab);
    if (loadModel) {
      clearMeshContent();
      switch (activeTab) {
        case "AddText":
          loadText(textDesign?.text);
          break;
        case "AddImage":
          if (image) {
            loadImageInRectangle(image, {
              x: 200, // X position of the image on the canvas
              y: 200, // Y position of the image on the canvas
              width: 1500, // Width of the image
              height: 1500, // Height of the image
            });
          }

          break;
        case "AddClipart":
          if (cliPart) {
            loadImageInRectangle(cliPart.file, {
              x: 20, // X position of the image on the canvas
              y: 20, // Y position of the image on the canvas
              width: 2000, // Width of the image
              height: 2000, // Height of the image
            });
          }

          break;

        default:
          break;
      }
    }
  }, [activeTab]);

  const updateModel = async () => {
    await clearGLBModel(); // منتظر حذف کامل مدل قبلی بمانید

    let modelUrl = null;

    switch (activeSection) {
      case 1:
        clearMeshContent();

      case 2:
        if (rose?.roseType === "Petal") {
          modelUrl = dataClient.design.typeRose[1].modelurl;
          cameraRef.current.position.set(
            0.006273909941400156,
            0.37749405638612843,
            -0.002136472170519654
          );
          cameraRef.current.quaternion.set(
            -0.48411684340949995,
            0.51545849204668,
            0.5143244434086887,
            0.4851842864484864
          );
          cameraRef.current.rotation.set(
            -1.570929477308698,
            0.0021984710232065083,
            1.6312875378015832,
            "XYZ"
          );
        }

        if (rose?.roseType === "Short-Stem-Rose")
          modelUrl = dataClient.design.typeRose[0].modelurl;

        break;
      case 3:
        if (typeof boxSelected === "object") {
          if (material.title === "acrylic") {
            modelUrl = boxSelected.modelUrl;

            cameraRef.current.position.set(
              -0.15611704323649497,
              0.13745843586913775,
              0.28718166281899365
            );

            cameraRef.current.quaternion.set(
              -0.152730487876685,
              -0.24885456321343485,
              -0.03977381329877597,
              0.9555955463565508
            );

            cameraRef.current.rotation.set(
              -0.35944094877877936,
              -0.48189508575687673,
              -0.17242240619382623,
              "XYZ"
            );

            const loaderhdr = new RGBELoader();
            loaderhdr.load(dataClient.lightSetting.hdriFile2, (texture) => {
              texture.mapping = THREE.EquirectangularReflectionMapping;
              sceneRef.current.environment = texture;
              sceneRef.current.environmentIntensity =
                dataClient.lightSetting.environmentIntensity2;
            });
          } else if (material.title === "Paper") {
            modelUrl = boxSelected.modelUrl;
            const loaderhdr = new RGBELoader();
            loaderhdr.load(dataClient.lightSetting.hdriFile1, (texture) => {
              texture.mapping = THREE.EquirectangularReflectionMapping;
              sceneRef.current.environment = texture;
              sceneRef.current.environmentIntensity =
                dataClient.lightSetting.environmentIntensity1;
            });
          }
        } else {
          console.log("no box selected yet");
          modelUrl = dataClient.design.typeRose[0].modelurl;
        }
        break;
      default:
        break;
    }

    if (modelUrl) {
      await clearGLBModel(); // منتظر حذف کامل مدل قبلی بمانید

      await loadGLBfile(modelUrl, 0); // مدل جدید رو لود کن

      // کمی صبر کن تا مدل جدید آماده بشه و بعد دیزاین رو اعمال کن
      setTimeout(() => {
        if (loadModel) {
          clearMeshContent();

          if (activeTab === "AddText") {
            loadText(textDesign?.text);
          }
          if (activeTab === "AddImage") {
            loadImageInRectangle(image, {
              x: 200, // X position of the image on the canvas
              y: 200, // Y position of the image on the canvas
              width: 1500, // Width of the image
              height: 1500, // Height of the image
            });
          }
          if (activeTab === "AddClipart") {
            loadImageInRectangle(cliPart.file, {
              x: 20, // X position of the image on the canvas
              y: 20, // Y position of the image on the canvas
              width: 2000, // Width of the image
              height: 2000, // Height of the image
            });
          }
        }
      }, 500); // نیم‌ثانیه صبر کن تا مدل لود بشه
    }
  };

  const updateDesign = async (modelUrl) => {
    await loadGLBfile(modelUrl, 0);

    // کمی صبر کن تا مدل جدید آماده بشه و بعد دیزاین رو اعمال کن
    setTimeout(() => {
      if (loadModel) {
        clearMeshContent();

        if (activeTab === "AddText") {
          loadText(textDesign?.text);
        }
        if (activeTab === "AddImage") {
          loadImageInRectangle(image, {
            x: 200, // X position of the image on the canvas
            y: 200, // Y position of the image on the canvas
            width: 1500, // Width of the image
            height: 1500, // Height of the image
          });
        }
        if (activeTab === "AddClipart") {
          loadImageInRectangle(cliPart.file, {
            x: 20, // X position of the image on the canvas
            y: 20, // Y position of the image on the canvas
            width: 2000, // Width of the image
            height: 2000, // Height of the image
          });
        }
      }
    }, 500); // نیم‌ثانیه صبر کن تا مدل لود بشه
  };

  useEffect(() => {
    if (firstLoad.current === false) {
      // console.log("activeSection-canvas>>", activeSection);
      setFlagScroll(false);

      if (loadModel) clearMeshContent();
      clearGLBModel();

      if (loadModel) updateModel();
    }
  }, [activeSection]);

  const [mainSizeImage, setMainSizeImage] = useState(1500);

  useEffect(() => {
    if (firstLoad.current === false) {
      // console.log("sizeImage-canvas>>", mainSizeImage + sizeImage * 200);

      const rectangleConfig = {
        x: 200, // starting x position
        y: 200, // starting y position
        width: mainSizeImage + sizeImage * 200,
        height: mainSizeImage + sizeImage * 200,
      };
      if (loadModel) loadImageInRectangle(image, rectangleConfig);
    }
  }, [sizeImage]);

  let mainOption;

  const logCameraPosition = () => {
    if (cameraRef.current) {
      const { x, y, z } = cameraRef.current.position;
    }
  };

  window.addEventListener("keydown", (e) => {
    if (e.key === "l") {
      // Press 'L' to log the camera's state
      // console.log("Camera Position:", cameraRef.current.position.toArray());
      // console.log("Camera Quaternion:", cameraRef.current.quaternion.toArray());
      // console.log(
      //   "Camera Rotation (Euler):",
      //   cameraRef.current.rotation.toArray()
      // );
    }
  });

  // function waitForFontToLoad(fontFamily) {
  //   return new Promise((resolve) => {
  //     document.fonts.load(`10pt ${fontFamily}`).then(resolve);
  //   });
  // }

  function waitForFontToLoad(fontFamily) {
    return new Promise((resolve, reject) => {
      document.fonts
        .load(`10pt ${fontFamily}`)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.error("Error loading font:", error);
          reject(error);
        });
    });
  }

  const loadText = (mainText) => {
    const lines = [
      mainText.line1,
      mainText.line2,
      mainText.line3,
      mainText.line4,
    ];
    const petalMesh = modelRef.current.getObjectByName("textlayer");

    const petalMeshes = [];

    modelRef.current.traverse((child) => {
      if (
        child instanceof THREE.Mesh &&
        child.name.toLowerCase().includes("print_zone")
      ) {
        petalMeshes.push(child); // Add matching meshes to an array
      }
    });

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    // تنظیم اندازه‌ی canvas برای بافت
    canvas.width = 512;
    canvas.height = 512;
    // معکوس کردن محور Y
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.translate(0, canvas.height); // انتقال نقطه شروع به پایین Canvas
    ctx.scale(1, -1); // وارونگی محور Y (بدون آینه‌ای کردن)

    let lineHeight = 100; // Adjust as needed

    const calculateFontSize = (text) => {
      if (text.length <= 5) {
        lineHeight = 100;
        return 130;
      } // Default size for short text
      if (text.length <= 10) {
        lineHeight = 80;

        return 100;
      } // Medium text
      if (text.length <= 15) {
        lineHeight = 50;

        return 50;
      }
      return 60; // Extra long text
    };

    // console.log("textDesign?.selectedTextColor", textDesign?.selectedTextColor);
    // استایل متن
    // const fontPath = fontFamily || "Arial"; // Use default font if path is not provided
    ctx.fillStyle = textDesign?.selectedTextColor.hex || "black"; // Use default color if not selected
    // const fontSize = 140;
    // ctx.font = `${fontSize}px ${fontPath}`;
    // ctx.fillStyle = 'black'; // رنگ متن
    // ctx.font = '140px Arial';
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.direction = "rtl";
    // نوشتن خطوط متن
    // lines.forEach((line, index) => {
    //   if (line) {
    //     ctx.fillText(line, canvas.width / 2, 100 + index * 80); // هر خط کمی پایین‌تر از خط قبلی
    //   }
    // });

    const totalHeight = lines.filter(Boolean).length * lineHeight; // Total height of the text block
    const startY = (canvas.height - totalHeight) / 2 + lineHeight / 2;

    // lines.forEach((line, index) => {
    //   if (line) {
    //     ctx.fillText(line, canvas.width / 2, startY + index * lineHeight);
    //   }
    // });

    // const font = new FontFace('CustomFont', `url(${textDesign?.fontFamilyPath})`);

    // font.load().then(() => {

    // })
    const fontSize = calculateFontSize(lines[0]);

    lines.forEach((line, index) => {
      if (line) {
        // const fontSize = calculateFontSize(line);
        ctx.font = `${fontSize}px ${textDesign?.fontFamily || "Arial"}`;
        ctx.fillStyle = textDesign?.selectedTextColor.hex || "black";
        ctx.fillText(line, canvas.width / 2, startY + index * lineHeight);
      }
    });

    // ساخت بافت از canvas
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;

    if (petalMesh) {
      if (petalMesh.material.map) {
        petalMesh.material.map.dispose();
      }
      petalMesh.material.map = null;

      petalMesh.material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
      });

      petalMesh.material.needsUpdate = true;
    } else if (petalMeshes.length > 0) {
      petalMeshes.forEach((petalMesh) => {
        // Remove the previous texture if exists
        if (petalMesh.material.map) {
          petalMesh.material.map.dispose();
          petalMesh.material.map = null;
        }

        // Apply the new texture
        petalMesh.material.map = null;
        petalMesh.material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true,
        });

        petalMesh.material.needsUpdate = true;
      });
    }
  };

  function updateMeshBaseColorTexture(
    baseColorTexturePath,
    colorCode,
    baseColorCode
  ) {
    const textureLoader = new THREE.TextureLoader();

    const newBaseColorTexture = textureLoader.load(baseColorTexturePath, () => {
      newBaseColorTexture.flipY = false;
      newBaseColorTexture.needsUpdate = true; // Ensure texture is updated after loading
    });

    modelRef.current.traverse((child) => {
      // if (child.isMesh && targetMeshes.includes(child.name)) {
      if (child.isMesh) {
        const material = child.material;

        if (material) {
          let color;
          if (
            child.name.includes("petal") ||
            (child.name.includes("Petal") &&
              !child.name.includes("print_zone") &&
              !child.name.includes("textlayer"))
          ) {
            color = colorCode.startsWith("#") ? colorCode : "#" + colorCode;
            material.color.set(color);
            material.map = newBaseColorTexture;
            material.normalmap = newNormalMapTexture;
            material.roughnessMap = newRoughnessMapTexture;
            material.needsUpdate = true;
          }
          if (child.name.includes("base")) {
            const color1 = baseColorCode.startsWith("#")
              ? baseColorCode
              : "#" + baseColorCode;
            material.color.set(color1);

            material.map = null;
            // material.normalmap = newNormalMapBaseRose;
            // material.roughnessMap = newRoughnessMapTexture;
            material.needsUpdate = true;
          }
        }
      }
    });
  }

  const init = () => {
    setLoadingMain(true);
    // Create scene

    const textureLoader = new THREE.TextureLoader();

    // Load your textures (update paths to your textures)
    newBaseColorTexture = textureLoader.load(
      "/textures/principal textures/red_02_E22202.png"
    );
    newNormalMapBaseRose = textureLoader.load(
      "/textures/principal textures/normal_base rosa.png"
    );
    newNormalMapTexture = textureLoader.load(
      "/textures/principal textures/Petal_Rose_Red_Normal.png"
    );
    newRoughnessMapTexture = textureLoader.load(
      "/textures/principal textures/rose_roughness.png"
    );

    const scene = new THREE.Scene();
    // scene.background = new THREE.Color(0xf0f0f0);
    sceneRef.current = scene;

    // Set up camera
    const camera = new THREE.PerspectiveCamera(
      45,
      canvasRef.current.clientWidth / canvasRef.current.clientHeight,
      0.01,
      1000
    );

    camera.position.set(
      0.31817526080789105,
      0.039400304623192675,
      -0.02908931569126374
    );

    camera.quaternion.set(
      -0.04133254261349739,
      0.7372057012213301,
      0.04528377071470179,
      0.6728809368066008
    );

    camera.rotation.set(
      -2.206768450613856,
      1.418069687034873,
      2.2123726704585045
    );

    camera.lookAt(0, 0, 0);
    cameraRef.current = camera;

    // Set up renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setClearColor(0x000000, 0); // Make the background transparent
    renderer.setSize(
      canvasRef.current.clientWidth,
      canvasRef.current.clientHeight
    );

    // renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // renderer.toneMapping = THREE.NeutralToneMapping;
    renderer.toneMapping = THREE.LinearToneMapping;
    renderer.toneMappingExposure = 1;

    rendererRef.current = renderer;
    canvasRef.current.appendChild(renderer.domElement);

    // Set up OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.enablePan = false;

    // controls.autoRotate = true
    controls.dampingFactor = 0.05;
    controls.enableZoom = true;
    // controls.minDistance = 0.15; // Set min zoom
    // controls.maxDistance = 2; // Set max zoom
    orbitRef.current = controls;

    const loaderhdr = new RGBELoader();

    loaderhdr.load(dataClient.lightSetting.hdriFile2, function (texture) {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      sceneRef.current.environment = texture; // Set the environment for the scene
      sceneRef.current.environmentIntensity =
        dataClient.lightSetting.environmentIntensity2; // Set the environment for the scene
    });

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.68);
    // directionalLight.castShadow = true;
    directionalLight.position.set(0, 10, 7);
    // sceneRef.current.add(directionalLight);

    // Lighting
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(5, 5, 5);
    // sceneRef.current.add(light);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    // sceneRef.current.add(ambientLight);

    // Animate function
    const animate = () => {
      requestAnimationFrame(animate);
      orbitRef.current.update();
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      cameraRef.current.aspect =
        canvasRef.current.clientWidth / canvasRef.current.clientHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(
        canvasRef.current.clientWidth,
        canvasRef.current.clientHeight
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (rendererRef.current) rendererRef.current.dispose();
    };
  };

  function clearGLBModel() {
    setLoadingMain(true);
    return new Promise((resolve) => {
      sceneRef.current.traverse((object) => {
        if (object.isMesh) {
          if (object.geometry) object.geometry.dispose();
          if (object.material) {
            if (Array.isArray(object.material)) {
              object.material.forEach((material) => disposeMaterial(material));
            } else {
              disposeMaterial(object.material);
            }
          }
        }
      });

      while (sceneRef.current.children.length > 0) {
        const child = sceneRef.current.children[0];
        sceneRef.current.remove(child);
      }

      requestAnimationFrame(resolve); // حل مشکل همزمانی با استفاده از `requestAnimationFrame`
      setLoadingMain(false);
    });
  }

  function loadGLBfile(url, n) {
    setLoadingMain(true);

    const loader = new GLTFLoader();

    // Load the GLB file
    loader.load(
      url,
      (gltf) => {
        modelRef.current = gltf.scene;
        sceneRef.current.add(gltf.scene);
        const gridHelper = new THREE.GridHelper(20, 20);
        // sceneRef.current.add(gridHelper);

        if (rose?.roseType !== "Petal")
          fitToView(modelRef.current, cameraRef.current, orbitRef.current);

        const axishelper = new THREE.AxesHelper(5);
        // sceneRef.current.add(axishelper);

        setLoadModel(true);
        if (rose?.roseColor) {
          updateMeshBaseColorTexture(
            rose?.roseColor.texture,
            rose?.roseColor.hex,
            rose?.roseColor.hexbase
          );
        }

        if (n === 3) {
          const glassMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xffffff, // Base color
            roughness: 0, // No roughness for clear glass
            transmission: 1, // Enables transparency (1 = fully transparent)
            thickness: 0.001, // Physical thickness of the object
            ior: 1.3, // Index of Refraction (glass = ~1.5)
            specularIntensity: 1, // Specular highlights
            specularColor: 0xffffff, // Color of specular highlights
            envMapIntensity: 0.8, // Intensity of reflections
            metalness: 0, // Non-metallic material
            clearcoat: 1, // Add clearcoat for shine
            clearcoatRoughness: 0, // Keep clearcoat smooth
          });

          sceneRef.current.traverse((object) => {
            if (object.isMesh && object.name.includes("Acrylic")) {
              object.material = glassMaterial; // Assign the material
            }
          });
        }
        setLoadingMain(false);
        setFlagScroll(true);
      },
      (xhr) => {
        // Optionally, you can monitor loading progress here
        // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        setLoadingMain(true);
      },
      (error) => {
        console.error("An error happened", error);
      }
    );
  }

  function disposeMaterial(material) {
    // Dispose of textures
    for (const key in material) {
      if (material[key] && material[key].isTexture) {
        material[key].dispose();
      }
    }
    material.dispose();
  }

  const loadImageInRectangle = (imagePath, rectangleConfig) => {
    // console.log("loadImageInRectangle");
    const { x, y, width, height } = rectangleConfig; // Rectangle config for image placement
    const targetMesh = modelRef.current.getObjectByName("textlayer");

    const loader = new THREE.TextureLoader();
    loader.load(
      imagePath, // Load the image from the path
      (texture) => {
        // Once the texture is loaded, we create a canvas
        const canvas = document.createElement("canvas");
        canvas.width = 2000; // Adjust canvas size as needed
        canvas.height = 2000; // Adjust canvas size as needed
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas without adding a background color
        ctx.translate(0, canvas.height); // انتقال نقطه شروع به پایین Canvas
        ctx.scale(1, -1); // وارونگی محور Y (بدون آینه‌ای کردن)

        // Draw the loaded image onto the canvas in the specified rectangle area
        const img = new Image();
        img.src = imagePath;
        img.onload = () => {
          ctx.drawImage(img, x, y, width, height); // Draw image in the rectangle

          // Create a canvas texture
          const canvasTexture = new THREE.CanvasTexture(canvas);
          canvasTexture.needsUpdate = true;

          // Apply sRGB encoding for proper gamma correction
          canvasTexture.colorSpace = THREE.SRGBColorSpace;

          if (targetMesh) {
            if (targetMesh.material.map) {
              targetMesh.material.map.dispose(); // Dispose of the previous texture to free memory
            }

            targetMesh.material.map = null;
            // Use the canvasTexture for the mesh material
            const material = new THREE.MeshStandardMaterial({
              map: canvasTexture, // Use canvas texture for material
              transparent: true, // Make the material transparent
              opacity: 1.0, // Full opacity for the image
            });

            // Assign the material to the target mesh
            targetMesh.material = material;
            targetMesh.material.needsUpdate = true;
          } else {
            const petalMeshes = [];

            modelRef.current.traverse((child) => {
              if (
                child instanceof THREE.Mesh &&
                child.name.toLowerCase().includes("print_zone")
              ) {
                petalMeshes.push(child); // Add matching meshes to an array
              }
            });

            petalMeshes.forEach((petalMesh) => {
              // Remove the previous texture if exists
              if (petalMesh.material.map) {
                petalMesh.material.map.dispose();
                petalMesh.material.map = null;
              }

              // Apply the new texture
              petalMesh.material.map = null;

              const material = new THREE.MeshStandardMaterial({
                map: canvasTexture, // Use canvas texture for material
                transparent: true, // Make the material transparent
                opacity: 1.0, // Full opacity for the image
              });

              // Assign the material to the target mesh
              petalMesh.material = material;
              petalMesh.material.needsUpdate = true;
            });
          }
        };
      },
      undefined,
      (error) => {
        console.error("Error loading image:", error);
      }
    );
  };

  const loadImage = (imagePath) => {
    const loader = new THREE.TextureLoader();
    const targetMesh = modelRef.current.getObjectByName("textlayer");

    const petalMeshes = [];
    modelRef.current.traverse((child) => {
      if (
        child instanceof THREE.Mesh &&
        child.name.includes("print_zone_logo")
      ) {
        petalMeshes.push(child); // Add matching meshes to an array
      }
    });
    loader.load(
      imagePath,
      (texture) => {
        if (targetMesh) {
          // Remove any previous content (text or clipart)
          // clearMeshContent();

          // Apply the image texture
          targetMesh.material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true, // Enable transparency for PNGs
          });
          targetMesh.material.needsUpdate = true;
        } else {
          petalMeshes.forEach((petalMesh) => {
            // Remove the previous texture if exists
            if (petalMesh.material.map) {
              petalMesh.material.map.dispose();
              petalMesh.material.map = null;
            }

            // Apply the new texture
            petalMesh.material.map = null;
            petalMesh.material = new THREE.MeshBasicMaterial({
              map: texture,
              transparent: true,
            });

            petalMesh.material.needsUpdate = true;
          });
        }
      },
      undefined,
      (error) => {
        console.error("Error loading image:", error);
      }
    );
  };

  const fitToView = (model, camera, controls) => {
    const box = new THREE.Box3().setFromObject(model); // Get model bounds
    const center = box.getCenter(new THREE.Vector3()); // Get center point
    const size = box.getSize(new THREE.Vector3()); // Get size of the model

    const maxDim = Math.max(size.x, size.y, size.z);
    const fitHeightDistance =
      maxDim / (2 * Math.tan(THREE.MathUtils.degToRad(camera.fov) / 2));

    const fitWidthDistance = fitHeightDistance / camera.aspect;
    let distance = Math.max(fitHeightDistance, fitWidthDistance);

    distance *= 1.8; // Add some padding (increase this if needed)

    // Set camera position
    const direction = new THREE.Vector3()
      .subVectors(camera.position, center)
      .normalize();
    camera.position.copy(direction.multiplyScalar(distance).add(center));
    camera.lookAt(center);

    // Update controls target
    controls.target.copy(center);
    controls.update();
  };

  const clearMeshContent = () => {
    const targetMesh = modelRef.current.getObjectByName("textlayer");

    const petalMeshes = [];
    modelRef.current.traverse((child) => {
      if (
        child instanceof THREE.Mesh &&
        child.name.toLowerCase().includes("printzone")
      ) {
        petalMeshes.push(child); // Add matching meshes to an array
      }
    });
    if (targetMesh && targetMesh.material) {
      // Dispose of the current material and texture
      if (targetMesh.material.map) {
        targetMesh.material.map.dispose();
        targetMesh.material.map = null;
      }

      // Clear the material
      targetMesh.material.dispose();
      targetMesh.material = new THREE.MeshBasicMaterial({
        color: 0xffffff, // Default blank material (white)
        transparent: true,
        opacity: 0, // Make it fully invisible
      });
      targetMesh.material.needsUpdate = true;
    } else {
      petalMeshes.forEach((petalMesh) => {
        // Remove the previous texture if exists
        if (petalMesh.material.map) {
          petalMesh.material.map.dispose();
          petalMesh.material.map = null;
        }

        // Apply the new texture
        petalMesh.material.dispose();
        petalMesh.material.map = null;
        petalMesh.material = new THREE.MeshBasicMaterial({
          color: 0xffffff, // Default blank material (white)
          transparent: true,
          opacity: 0,
        });

        petalMesh.material.needsUpdate = true;
      });
    }
  };

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      init(); // Call init function to set up Three.js
    }
  }, []);

  useEffect(() => {
    if (loadModel) {
      console.log("textDesign", textDesign);
      loadText(textDesign.text);
    }
  }, [textDesign]);

  useEffect(() => {
    if (loadModel) {
      if (image) {
        loadImageInRectangle(image, {
          x: 200, // X position of the image on the canvas
          y: 200, // Y position of the image on the canvas
          width: 1500, // Width of the image
          height: 1500, // Height of the image
        });

        // ,{width:1000,height:1000}
      }
    }
  }, [image]);

  useEffect(() => {
    if (loadModel) {
      if (cliPart) {
        loadImageInRectangle(cliPart.file, {
          x: 20, // X position of the image on the canvas
          y: 20, // Y position of the image on the canvas
          width: 2000, // Width of the image
          height: 2000, // Height of the image
        });
      }
    }
  }, [cliPart]);

  useEffect(() => {
    if (firstLoad.current === false) {
      if (rose?.roseType === "Petal") {
        clearGLBModel();
        loadGLBfile(dataClient.design.typeRose[1].modelurl, 0);

        cameraRef.current.position.set(
          0.006273909941400156,
          0.37749405638612843,
          -0.002136472170519654
        );
        cameraRef.current.quaternion.set(
          -0.48411684340949995,
          0.51545849204668,
          0.5143244434086887,
          0.4851842864484864
        );
        cameraRef.current.rotation.set(
          -1.570929477308698,
          0.0021984710232065083,
          1.6312875378015832,
          "XYZ"
        );
      } else if (rose?.roseType === "Short-Stem-Rose") {
        clearGLBModel();
        loadGLBfile(dataClient.design.typeRose[0].modelurl, 0);

        cameraRef.current.position.set(
          0.31817526080789105,
          0.039400304623192675,
          -0.02908931569126374
        );
        cameraRef.current.quaternion.set(
          -0.04133254261349739,
          0.7372057012213301,
          0.04528377071470179,
          0.6728809368066008
        );
        cameraRef.current.rotation.set(
          -2.206768450613856,
          1.418069687034873,
          2.2123726704585045
        );
      }
    }
  }, [rose?.roseType]);

  useEffect(() => {
    if (firstLoad.current === false) {
      if (activeSection === 3) {
        let URL;
        if (typeof boxSelected === "object") {
          if (material.title === "acrylic") {
            clearGLBModel();
            URL = boxSelected.modelUrl;
            // loadGLBfile(boxSelected.modelUrl, 0);

            cameraRef.current.position.set(
              -0.15611704323649497,
              0.13745843586913775,
              0.28718166281899365
            );

            cameraRef.current.quaternion.set(
              -0.152730487876685,
              -0.24885456321343485,
              -0.03977381329877597,
              0.9555955463565508
            );

            cameraRef.current.rotation.set(
              -0.35944094877877936,
              -0.48189508575687673,
              -0.17242240619382623,
              "XYZ"
            );

            const loaderhdr = new RGBELoader();

            loaderhdr.load(
              dataClient.lightSetting.hdriFile2,
              function (texture) {
                texture.mapping = THREE.EquirectangularReflectionMapping;
                sceneRef.current.environment = texture; // Set the environment for the scene
                sceneRef.current.environmentIntensity =
                  dataClient.lightSetting.environmentIntensity2; // Set the environment for the scene
              }
            );
            const plan1 = new THREE.PlaneGeometry(20, 20);
            const material1 = new THREE.MeshBasicMaterial({
              color: 0xfffafa,
              side: THREE.DoubleSide,
            });
            const mesh1 = new THREE.Mesh(plan1, material1);
            mesh1.rotation.x = Math.PI / 2;
            mesh1.position.set(0, -2.5, 0);
            // sceneRef.current.add(mesh1)

            // تعریف Plane دوم که عمود است
            const geometry2 = new THREE.PlaneGeometry(20, 20);
            const material2 = new THREE.MeshBasicMaterial({
              color: 0xfffafa,
              side: THREE.DoubleSide,
            });
            const plane2 = new THREE.Mesh(geometry2, material2);
            // sceneRef.current.add(plane2);

            // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
            plane2.position.set(0, 2.5, 2.5); // تغییر مکان به ضلع بالای Plane اول

            // تعریف Plane دوم که عمود است
            const geometry3 = new THREE.PlaneGeometry(20, 20);
            const material3 = new THREE.MeshBasicMaterial({
              color: 0xfffafa,
              side: THREE.DoubleSide,
            });
            const plane3 = new THREE.Mesh(geometry3, material3);
            // sceneRef.current.add(plane3);

            // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
            plane3.position.set(0, 2.5, -2.5); // تغییر مکان به ضلع بالای Plane اول
            plane3.rotation.y = Math.PI; // چرخش 180 درجه برای پشت

            // تعریف Plane دوم که عمود است
            const geometry4 = new THREE.PlaneGeometry(20, 20);
            const material4 = new THREE.MeshBasicMaterial({
              color: 0xfffafa,
              side: THREE.DoubleSide,
            });
            const plane4 = new THREE.Mesh(geometry4, material4);
            // sceneRef.current.add(plane4);

            // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
            plane4.position.set(2.5, 2.5, 0); // تغییر مکان به ضلع بالای Plane اول
            plane4.rotation.y = -Math.PI / 2; // چرخش 90 درجه روی محور Y (عمود بر Plane اول)

            // تعریف Plane دوم که عمود است
            const geometry5 = new THREE.PlaneGeometry(20, 20);
            const material5 = new THREE.MeshBasicMaterial({
              color: 0xfffafa,
              side: THREE.DoubleSide,
            });
            const plane5 = new THREE.Mesh(geometry5, material5);
            // sceneRef.current.add(plane5);

            // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
            plane5.position.set(-2.5, 2.5, 0); // تغییر مکان به ضلع بالای Plane اول
            plane5.rotation.y = Math.PI / 2; // چرخش 90 درجه روی محور Y (عمود بر Plane اول)

            updateDesign(URL);
          } else if (material.title === "Paper") {
            clearGLBModel();
            // loadGLBfile(boxSelected.modelUrl, 0);
            URL = boxSelected.modelUrl;
            const loaderhdr = new RGBELoader();

            loaderhdr.load(
              dataClient.lightSetting.hdriFile1,
              function (texture) {
                texture.mapping = THREE.EquirectangularReflectionMapping;
                sceneRef.current.environment = texture; // Set the environment for the scene
                sceneRef.current.environmentIntensity =
                  dataClient.lightSetting.environmentIntensity1;
              }
            );

            updateDesign(URL);
          }
        } else {
          console.log("no box selected yet");
          clearGLBModel();
        }
      }
    }
  }, [boxSelected]);

  useEffect(() => {
    if (firstLoad.current === false && loadModel) {
      if (rose?.roseColor) {
        updateMeshBaseColorTexture(
          rose?.roseColor.texture,
          rose?.roseColor.hex,
          rose?.roseColor.hexbase
        );
      }
    }
  }, [rose?.roseColor]);

  const handleReset = () => {
    fitToView(modelRef?.current, cameraRef.current, orbitRef.current);
    // cameraRef.current.position.set(
    //   -0.3518734965497957,
    //   0.23914218048842792,
    //   0.0804560829307368
    // );
  };

  const zoomStep2 = 1; // Adjust this value for the zoom step
  const minZoomDistance2 = 5; // Minimum zoom distance
  const maxZoomDistance2 = 50; // Maximum zoom distance

  const handleZoomIn = () => {
    cameraRef.current.fov = Math.max(10, cameraRef.current.fov - 1); // Decrease FOV to zoom in
    cameraRef.current.updateProjectionMatrix();
  };

  const handleZoomOut = () => {
    cameraRef.current.fov = Math.min(100, cameraRef.current.fov + 1); // Increase FOV to zoom out
    cameraRef.current.updateProjectionMatrix();
  };

  return (
    <>
      <div className="CanvasPart">
        <div className="Logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="Chat-min992">
          <Chat />
        </div>

        <div className="Canvas" ref={canvasRef}></div>

        <div className="CanvasPart-controler">
          <button className="CanvasPart-controler-item" onClick={handleReset}>
            <i
              className="bi bi-arrow-counterclockwise CanvasPart-controler-item-i"
              style={{
                transform: "rotate(.09turn)",
              }}
            ></i>
          </button>
          <button className="CanvasPart-controler-item" onClick={handleZoomOut}>
            <i className="bi bi-dash-lg CanvasPart-controler-item-i"></i>
          </button>
          <button className="CanvasPart-controler-item" onClick={handleZoomIn}>
            <i className="bi bi-plus-lg CanvasPart-controler-item-i"></i>
          </button>
        </div>
      </div>
    </>
  );
}
