import { useContext, useEffect, useRef, useState } from "react";
import { HomeContext } from "../../../../../../Context/HomeContext";

export default function ImageUpload() {
  const {
    setDesignPanelMenu,
    image,
    setImage,
    setActiveSection,
    handleScrollTo,
  } = useContext(HomeContext);

  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  const scaleStep = 0.05;
  const maxScale = 2;
  const minScale = 0.5;

  useEffect(() => {
    setActiveSection(2);
    handleScrollTo(1);
  }, []);

  useEffect(() => {
    // console.log(
    //   "containerRef.current.clientHeight",
    //   containerRef?.current?.clientHeight
    // );
    console.log("scale >>", scale);
    console.log("position>>", position);
  }, [containerRef?.current?.clientHeight, scale]);

  const handleZoom = (newScale) => {
    const container = containerRef.current;
    if (!container) return;

    // محاسبه مرکز ظرف
    const containerCenterX = container.clientWidth / 2;
    const containerCenterY = container.clientHeight / 2;

    // محاسبه موقعیت جدید تصویر به طوری که نقطه‌ای که در حال حاضر در transform قرار دارد نسبت به مرکز، حفظ شود
    // const newPosX =
    //   containerCenterX - ((containerCenterX - position.x) * newScale) / scale;
    // const newPosY =
    //   containerCenterY - ((containerCenterY - position.y) * newScale) / scale;

    const newPosX = parseFloat(
      (
        containerCenterX -
        ((containerCenterX - position.x) * newScale) / scale
      ).toFixed(2)
    );
    const newPosY = parseFloat(
      (
        containerCenterY -
        ((containerCenterY - position.y) * newScale) / scale
      ).toFixed(2)
    );
    setPosition({ x: newPosX, y: newPosY });
    setScale(newScale);
  };

  // دکمه زوم این (افزایش مقیاس)
  const handleZoomIn = () => {
    if (scale < maxScale) {
      // const newScale = Math.min(scale + scaleStep, maxScale);
      const newScale = Math.min(
        parseFloat((scale + scaleStep).toFixed(2)),
        maxScale
      );
      handleZoom(newScale);
    }
  };

  // دکمه زوم اوت (کاهش مقیاس)
  const handleZoomOut = () => {
    if (scale > minScale) {
      // const newScale = Math.max(scale - scaleStep, minScale);
      const newScale = Math.max(
        parseFloat((scale - scaleStep).toFixed(2)),
        minScale
      );

      handleZoom(newScale);
    }
  };

  const progressPercentage = ((scale - minScale) / (maxScale - minScale)) * 100;

  // Start drag
  const handleDragStart = (e) => {
    setDragging(true);
    setStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  // Dragging
  const handleDragging = (e) => {
    if (!dragging) return;
    setPosition({
      x: e.clientX - startPos.x,
      y: e.clientY - startPos.y,
    });
  };

  // End drag
  const handleDragEnd = () => {
    setDragging(false);
  };

  const inputRef = useRef(null);

  const handleImageChange = (e) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const cropImage = () => {
    const img = new Image();
    // اطمینان از crossOrigin در صورت نیاز
    img.crossOrigin = "anonymous";
    img.src = image;

    // اگر تصویر از قبل در کش است، ممکن است onload فراخوانی نشود.
    if (img.complete) {
      processCrop(img);
    } else {
      img.onload = () => processCrop(img);
    }
  };

  const processCrop = (img) => {
    // دریافت ابعاد ظرف تصویر
    const container = containerRef.current;
    if (!container) return; // اطمینان از وجود ظرف

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    // اندازه‌ی ناحیه کراپ طبق ماسک دایره‌ای (قطر 220 پیکسل)
    const cropSize = 170;

    // موقعیت ناحیه کراپ در ظرف (مرکز ظرف)
    const cropLeft = (containerWidth - cropSize) / 2;
    const cropTop = (containerHeight - cropSize) / 2;

    // محاسبه نسبت‌های اولیه برای object-fit: cover
    const containerRatio = containerWidth / containerHeight;
    const imageRatio = img.naturalWidth / img.naturalHeight;
    let scaleFactor, baseOffsetX, baseOffsetY;

    if (imageRatio > containerRatio) {
      // تصویر از لحاظ عرض بزرگتر است؛ به این معنی که ارتفاع به اندازه ظرف تنظیم می‌شود
      scaleFactor = img.naturalHeight / containerHeight;
      const effectiveWidth = img.naturalWidth / scaleFactor; // عرض نمایش داده شده تصویر
      baseOffsetX = (containerWidth - effectiveWidth) / 2; // افست افقی اعمال شده توسط object-fit
      baseOffsetY = 0;
    } else {
      // تصویر از لحاظ ارتفاع بزرگتر است؛ عرض به اندازه ظرف تنظیم می‌شود
      scaleFactor = img.naturalWidth / containerWidth;
      const effectiveHeight = img.naturalHeight / scaleFactor; // ارتفاع نمایش داده شده تصویر
      baseOffsetX = 0;
      baseOffsetY = (containerHeight - effectiveHeight) / 2; // افست عمودی
    }

    // محاسبه مختصات برداشت از تصویر در ابعاد اصلی (natural coordinates)
    const sx = ((cropLeft - (baseOffsetX + position.x)) / scale) * scaleFactor;
    const sy = ((cropTop - (baseOffsetY + position.y)) / scale) * scaleFactor;
    const sWidth = (cropSize / scale) * scaleFactor;
    const sHeight = (cropSize / scale) * scaleFactor;

    // ایجاد canvas با ابعاد ناحیه کراپ
    const canvas = document.createElement("canvas");
    canvas.width = cropSize;
    canvas.height = cropSize;
    const ctx = canvas.getContext("2d");

    // تنظیم clipping region به صورت دایره‌ای (دایره‌ای با شعاع 110 پیکسل)
    ctx.beginPath();
    ctx.arc(cropSize / 2, cropSize / 2, cropSize / 2, 0, Math.PI * 2);
    ctx.clip();

    // کشیدن تصویر روی canvas در ناحیه دایره‌ای
    ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, cropSize, cropSize);

    // تبدیل بوم به داده Base64
    const croppedImage = canvas.toDataURL("image/png");
    setImage(croppedImage);
  };

  return (
    <>
      <div className="ImageUpload">
        <div className="ImageUpload-title">
          <span>Upload Image</span>
        </div>

        <div className="ImageUpload-container">
          <div>
            <div className="ImageUpload-view">
              <div
                className="ImageUpload-view-img"
                ref={containerRef}
                onMouseDown={handleDragStart}
                onMouseMove={handleDragging}
                onMouseUp={handleDragEnd}
                onMouseLeave={handleDragEnd}
                style={{
                  cursor: dragging ? "grabbing" : "grab",
                }}
              >
                <img
                  src={image}
                  alt=""
                  style={{
                    transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                    transformOrigin: "top left",
                  }}
                />
              </div>
              <div className="ImageUpload-view-editHandler">
                <button onClick={handleZoomOut} disabled={scale <= minScale}>
                  <i className="bi bi-dash-lg"></i>
                </button>
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
                <button onClick={handleZoomIn} disabled={scale >= maxScale}>
                  <i className="bi bi-plus-lg"></i>
                </button>
              </div>
            </div>
            <div className="ImageUpload-edit">
              <div className="ImageUpload-edit-tryAgain" onClick={handleClick}>
                <p>Try another Picture</p>
                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  onChange={handleImageChange}
                />
              </div>
            </div>
          </div>
        </div>

        <button
          className="ImageUpload-confirmBtn"
          onClick={() => {
            setDesignPanelMenu(2);
            cropImage();
          }}
        >
          Confirm
        </button>
      </div>
    </>
  );
}
