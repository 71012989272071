import { useContext, useEffect, useState } from "react";
import Loading from "../../../../Loading/Loading";
import { HomeContext } from "../../../../../Context/HomeContext";
import dataClient from "../../../../../../../assets/Json/dataClient.json";
import empty from "../../../../../../../assets/empty.png";

export default function Boxes() {
  const {
    material,
    quantity,
    boxSelected,
    setBoxSelected,
    loading,
    setLoading,
  } = useContext(HomeContext);

  const [boxesList, setBoxesList] = useState([]);

  const handleBoxesFilter = () => {
    if (material?.title === "acrylic") {
      const list = dataClient?.design?.packaging?.boxes?.acrylic?.filter(
        (item) => quantity?.number === item?.quantity
      );

      setBoxesList(list || []);
      setBoxSelected(list[0]);
    } else if (material?.title === "Paper") {
      const list = dataClient?.design?.packaging?.boxes?.Paper.filter(
        (item) => quantity?.number === item?.quantity
      );
      setBoxesList(list || []);
      setBoxSelected(list[0]);
    } else {
      setBoxesList(null);
    }
  };

  useEffect(() => {
    handleBoxesFilter();
  }, [quantity, material]);

  const handleImageLoad = (index) => {
    setLoading((prev) => ({ ...prev, [index]: false }));
  };

  const handleImageError = (index) => {
    setLoading((prev) => ({ ...prev, [index]: false }));
  };

  return (
    <>
      <div className="Rose-Boxes">
        <div className="Rose-Boxes-title">
          <span>Boxes</span>
        </div>
        <div className="Rose-Boxes-list">
          {boxesList?.map((a) => {
            return (
              <section
                className="Rose-Boxes-list-item"
                key={a.id}
                onClick={() => {
                  setBoxSelected(a);
                }}
                style={{
                  border:
                    boxSelected?.imageSrc === a.imageSrc
                      ? "2px solid #000000"
                      : "1px solid #393C41",
                }}
              >
                {loading[a.id] !== false && <Loading />}
                <img
                  onLoad={() => handleImageLoad(a.id)}
                  onError={() => handleImageError(a.id)}
                  style={{
                    display: loading[a.id] === false ? "block" : "none",
                  }}
                  src={a.imageSrc}
                  alt={a.imageSrc}
                />
              </section>
            );
          })}

          {boxesList?.length == 0 && (
            <div className="Rose-Boxes-list-empty">
              <img src={empty} alt="empty" />
              <span>There is no product.</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
