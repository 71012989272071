import { useContext } from "react";
import { HomeContext } from "../../Context/HomeContext";
import Lottie from "lottie-react";
import animationFile from "./Animation - 1729240983685.json";

export default function LoadingMain() {
  const { loadingMain } = useContext(HomeContext);

  return (
    <>
      <div
        className="LoadingMain"
        style={{
          display: loadingMain ? "flex" : "none",
        }}
      >
        <Lottie animationData={animationFile} loop={true} />
      </div>
    </>
  );
}
