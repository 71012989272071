import { useContext, useEffect, useRef, useState } from "react";
import icon2 from "../../../../assets/icon2.png";
import icon3 from "../../../../assets/icon3.png";
import icon4 from "../../../../assets/icon4.png";
import icon5 from "../../../../assets/icon5.png";
import icon51 from "../../../../assets/icon5+.png";
import icon6 from "../../../../assets/icon6.png";
import { HomeContext } from "../../Context/HomeContext";

export default function DesignPanelToggler() {
  const { activeSection, handleScrollTo } = useContext(HomeContext);
  // const [responsive, setResponsive] = useState(true);

  // useEffect(() => {
  //   if (window.innerWidth >= 993) {
  //     setResponsive(true);
  //   } else {
  //     setResponsive(false);
  //   }
  // }, []);

  return (
    <>
      <div className="PanelToggler">
        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            <img
              src={icon6}
              alt="pointer"
              style={{
                display: activeSection === 1 ? "inline" : "none",
              }}
            />
          </div>

          <div
            className={`PanelToggler-item-icon ${
              activeSection === 1
                ? "ActiveI"
                : activeSection > 1
                ? "PastI"
                : "FutureI"
            }`}
            onClick={() => {
              handleScrollTo(0);
            }}
          >
            <img src={icon2} alt="Choose Type" width="24px" height="24px" />
          </div>
          <div className="PanelToggler-item-text">Rose</div>
        </section>

        <div className="PanelToggler-divider">
          <div className={activeSection >= 2 ? "PastD" : "FutureD"}></div>
        </div>

        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {activeSection === 2 ? <img src={icon6} alt="pointer" /> : null}
          </div>
          <div
            className={`PanelToggler-item-icon ${
              activeSection === 2
                ? "ActiveI"
                : activeSection > 2
                ? "PastI"
                : "FutureI"
            }`}
            onClick={() => {
              handleScrollTo(1);
            }}
          >
            <img src={icon4} alt="Design Rose" width="18px" height="18px" />
          </div>
          <div className="PanelToggler-item-text">Design</div>
        </section>

        <div className="PanelToggler-divider">
          <div className={activeSection >= 3 ? "PastD" : "FutureD"}></div>
        </div>

        <section className="PanelToggler-item">
          <div
            className="PanelToggler-item-pointer"
            style={{
              right: "-10px",
            }}
          >
            {activeSection === 3 && <img src={icon6} alt="pointer" />}
          </div>
          <div
            className={`PanelToggler-item-icon ${
              activeSection === 3 ? "ActiveI" : "FutureI"
            }`}
            onClick={() => {
              handleScrollTo(2);
            }}
          >
            {activeSection === 3 ? (
              <img src={icon51} alt="Packaging" width="20px" height="20px" />
            ) : (
              <img src={icon5} alt="Packaging" width="20px" height="20px" />
            )}
          </div>
          <div className="PanelToggler-item-text">Packaging</div>
        </section>
      </div>
    </>
  );
}
