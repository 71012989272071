import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import dataClient from "../../../../../../../assets/Json/dataClient.json";

export default function Quabtity() {
  const { quantity, setQuantity, material } = useContext(HomeContext);

  useEffect(() => {
    setQuantity(dataClient.design?.packaging?.quantity[0]);

  }, [material]);

  return (
    <>
      <section className="Rose-quantity">
        <div className="Rose-quantity-title">
          <span>Quantity</span>
        </div>
        <div className="Rose-quantity-list">
          {dataClient.design?.packaging?.quantity?.map((q) => {
            return (
              <div
                className="Rose-quantity-list-item"
                key={q.id}
                onClick={() => {
                  setQuantity(q);
                }}
              >
                <div
                  className="Rose-quantity-list-item-image"
                  style={{
                    border:
                      quantity?.item === q.item
                        ? "2px solid #000000"
                        : "1px solid #393C41",
                    backgroundColor:
                      quantity?.item === q.item ? "#F8E8E9" : "#ffffff",
                  }}
                >
                  <img src={q.image} alt={q.item} />
                </div>
                <p
                  className="Rose-quantity-list-item-text"
                  style={{
                    fontWeight: quantity?.item === q.item ? "700" : "400",
                  }}
                >
                  {q.title}
                </p>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
